/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

/** this file is used by project to add new icons */
import dynamicHydrate from '@bluheadless/ui-logic/src/dynamic-hydration/dynamic-hydrate'
import EmptyIcon from '../icon/empty'

export * from './project-related-icons'
export const CheckboxOnIconColored = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/checkbox-on-icon-colored'),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const CheckboxOnIconColoredSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/checkbox-on-icon-colored').then((mod) => mod.CheckboxOnIconColoredSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const HowToOrderIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/how-to-order-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const HowToOrderIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/how-to-order-icon').then((mod) => mod.HowToOrderIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const LogoIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/logo-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const LogoIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/logo-icon').then((mod) => mod.LogoIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const PaymentIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/payment-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const PaymentIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/payment-icon').then((mod) => mod.PaymentIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const ReturnIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/return-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const ReturnIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/return-icon').then((mod) => mod.ReturnIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const ShippingIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/shipping-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const ShippingIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/shipping-icon').then((mod) => mod.ShippingIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const TriangleIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/triangle-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const TriangleIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/triangle-icon').then((mod) => mod.TriangleIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)

/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxOnIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M19 5v14H5V5z" fill="currentColor" />
		<path fillRule="evenodd" fill="currentColor" d="M23 23H1V1h22zM3 21h18V3H3z" />
	</svg>
))
CheckboxOnIconSvg.displayName = 'CheckboxOnIconSvg'

const CheckboxOnIcon = forwardRef((props, ref) => <Icon component={CheckboxOnIconSvg} ref={ref} {...props} />)
CheckboxOnIcon.displayName = 'CheckboxOnIcon'

CheckboxOnIcon.defaultProps = {
	...Icon.defaultProps,
}
CheckboxOnIcon.propTypes = {
	...Icon.propTypes,
}

export default CheckboxOnIcon
export { CheckboxOnIconSvg }
